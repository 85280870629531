import get from 'lodash/get';
import find from 'lodash/find';
import moment from 'moment';
import { mtaStore } from '../store/mta-store';
import { lookupStore } from '../store/lookup-store';
import { addOnCodes, quoteStore } from '../store/quote-store';
import { salesforceStore } from '../store/salesforce-store';
import { renewalStore } from '../store/renewal-store';
import { stepStore } from '../store/step-store';

function fillStepStoreValues(data) {
  const storeValues = data?.storeValues;
  if (!storeValues.isPastPrice && storeValues.isPastPrice !== undefined) {
    stepStore.step = storeValues?.step || 1;
    stepStore.previousStepValue = storeValues?.previousStepValue || 0;
  }
}

export const populateAppData = formik => {
  const policy = mtaStore.mta || renewalStore.renewal;
  const policyData = get(policy, 'transactionData.dataPoints');
  if (!policyData) {
    return;
  }

  const { setValues, values } = formik;
  const Policy = get(policyData, '[0].dataPointData');
  const Policyholder = get(Policy, 'Policyholder[0]');
  const Address = get(Policyholder, 'Address');
  const GenericInsuredObject = get(policyData, '[1].dataPointData');

  const underwrittenAddOns = get(policy, 'underwritingOutcome.underwrittenOptions[0].underwrittenAddOns');

  let excessProtection = 'None';
  let legalAddOn = false;
  if (underwrittenAddOns) {
    underwrittenAddOns.forEach(addOn => {
      if (addOn?.selected === true) {
        if (addOn?.addOnCode === 'EXCESSPROTECTION1000') excessProtection = 1000;
        if (addOn?.addOnCode === 'EXCESSPROTECTION500') excessProtection = 500;
        if (addOn?.addOnCode === 'LEGALEXPENSES') legalAddOn = true;
      }
    });
  }

  setValues({
    ...values,
    legalAddOn,
    excessProtection,
    firstName: get(Policyholder, 'FirstName'),
    lastName: get(Policyholder, 'LastName'),
    email: get(Policyholder, 'Email'),
    phone: get(Policyholder, 'Telephone'),
    dateOfBirth: moment(get(Policyholder, 'DateOfBirth'), 'YYYY-MM-DD').format('DD/MM/YYYY'),
    title: {
      code: get(Policyholder, 'Title.code'),
      description: get(
        find(lookupStore.titles, a => a.code === get(Policyholder, 'Title.code')),
        'value'
      )
    },
    address: {
      postcode: get(Address, 'Postcode'),
      postTown: get(Address, 'PostTown'),
      addressLine1: get(Address, 'Line1'),
      addressLine2: get(Address, 'Line2')
    },
    companyName: get(Policyholder, 'CompanyName.code'),
    companyRegNumber: get(Policyholder, 'CompanyReg'),
    tradingAs: {
      code: get(Policyholder, 'TradingAs'),
      description: get(
        find(lookupStore.tradingAs, a => a.code === get(Policyholder, 'TradingAs')),
        'value'
      )
    },
    yearsTrading: get(Policyholder, 'YearsTrading'),
    legalStatus: {
      code: get(Policyholder, 'LegalStatus.code'),
      description: get(
        find(lookupStore.legalStatuses, a => a.code === get(Policyholder, 'LegalStatus.code')),
        'value'
      )
    },
    Turnover: get(Policy, 'Turnover'),
    PartnershipReferral: get(Policy, 'PartnershipReferral'),
    AdditionalPractitioners: get(Policy, 'AdditionalPractitioners'),
    NumberOfAdditionalPractitioners: get(Policy, 'AdditionalPractitioners')?.code,
    LimitOfIndemnity: {
      code: get(GenericInsuredObject, 'LimitOfIndemnity.code'),
      description: get(
        find(lookupStore.axisLimitOfIndemnity, a => a.code === get(GenericInsuredObject, 'LimitOfIndemnity.code')),
        'value'
      )
    },
    PractitionerLicence: get(GenericInsuredObject, 'PractitionerLicence'),
    PractitionerProfessionClinical: {
      code: get(GenericInsuredObject, 'PractitionerProfessionClinical.code'),
      description: get(
        find(
          lookupStore.axisPractitionerProfession,
          a => a.code === get(GenericInsuredObject, 'PractitionerProfessionClinical.code')
        ),
        'value'
      )
    },
    promotionalCode: get(Policy, 'PromotionalCode')
  });
};

export const populateAppDataFromSavedQuote = (formik, data) => {
  const quoteData = get(data, 'transactionData.dataPoints');
  if (!quoteData) {
    return;
  }

  const { setValues, values } = formik;
  const Policy = get(quoteData, '[0].dataPointData');
  const Policyholder = get(Policy, 'Policyholder[0]');
  const Address = get(Policyholder, 'Address');
  const GenericInsuredObject = get(quoteData, '[1].dataPointData');
  const underwrittenAddOns = get(data, 'underwrittenAddOns');
  const legalAddOn = underwrittenAddOns?.find(addOn => addOn.addOnCode === addOnCodes.legalAddOn)?.selected || false;
  const selectedExcess = underwrittenAddOns?.find(addOn => addOnCodes.legalAddOn !== addOn.addOnCode && addOn.selected);
  const excessProtection = parseInt(quoteStore.getExcessCodeValue(get(selectedExcess, 'addOnCode')), 10) || 'None';
  quoteStore.excessProtectionSelected = excessProtection;
  quoteStore.legalAddOnSelected = legalAddOn;
  salesforceStore.salesforceLeadId = Policy.SalesforceLeadID;
  salesforceStore.salesforceOpportunityId = Policy.SalesforceID;
  salesforceStore.salesforceContactId = Policy.SalesforceContactID;
  setValues({
    ...values,
    firstName: get(Policyholder, 'FirstName'),
    lastName: get(Policyholder, 'LastName'),
    email: get(Policyholder, 'Email'),
    phone: get(Policyholder, 'Telephone'),
    dateOfBirth: moment(get(Policyholder, 'DateOfBirth'), 'YYYY-MM-DD').format('DD/MM/YYYY'),
    title: {
      code: get(Policyholder, 'Title.code'),
      description: get(
        find(lookupStore.titles, a => a.code === get(Policyholder, 'Title.code')),
        'value'
      )
    },
    address: {
      postcode: get(Address, 'Postcode'),
      postTown: get(Address, 'PostTown'),
      addressLine1: get(Address, 'Line1'),
      addressLine2: get(Address, 'Line2')
    },
    companyName: get(Policyholder, 'CompanyName.code'),
    companyRegNumber: get(Policyholder, 'CompanyReg'),
    tradingAs: {
      code: get(Policyholder, 'TradingAs'),
      description: get(
        find(lookupStore.tradingAs, a => a.code === get(Policyholder, 'TradingAs')),
        'value'
      )
    },
    yearsTrading: get(Policyholder, 'YearsTrading'),
    legalStatus: {
      code: get(Policyholder, 'LegalStatus.code'),
      description: get(
        find(lookupStore.legalStatuses, a => a.code === get(Policyholder, 'LegalStatus.code')),
        'value'
      )
    },
    Turnover: get(Policy, 'Turnover'),
    PartnershipReferral: get(Policy, 'PartnershipReferral'),
    AdditionalPractitioners: get(Policy, 'AdditionalPractitioners'),
    NumberOfAdditionalPractitioners: get(Policy, 'AdditionalPractitioners')?.code,
    LimitOfIndemnity: get(GenericInsuredObject, 'LimitOfIndemnity'),
    PractitionerLicence: get(GenericInsuredObject, 'PractitionerLicence'),
    PractitionerProfessionClinical: get(GenericInsuredObject, 'PractitionerProfessionClinical'),
    legalAddOn,
    excessProtection,
    promotionalCode: get(Policy, 'PromotionalCode')
  });
};

export const populateAppDataFromSalesforceLead = (formik, quoteData) => {
  if (!quoteData) {
    return;
  }
  quoteStore.quoteRef = quoteData.quoteRef;
  salesforceStore.salesforceLeadId = quoteData.leadId;
  salesforceStore.salesforceOpportunityId = quoteData.opportunityId;

  const localStorageData = JSON.parse(
    localStorage.getItem(salesforceStore.salesforceOpportunityId || salesforceStore.salesforceLeadId)
  );

  if (quoteData.opportunityId && get(quoteData, 'quoteMetadata.quoteState') !== 'PENDING') {
    populateAppDataFromSavedQuote(formik, quoteData);
    if (get(quoteData, 'quoteMetadata.quoteState') === 'ISSUED') {
      quoteStore.prevQuote = get(quoteData, 'quoteMetadata.quoteRef');
    }
  } else {
    const data = get(quoteData, 'transactionData.dataPoints');
    if (!quoteData) {
      return;
    }
    const { setValues, values } = formik;
    const Quote = get(data, '[0].dataPointData');
    const Policyholder = get(Quote, 'Policyholder[0]');

    let parsedFormData;
    if (localStorageData) {
      parsedFormData = JSON.parse(localStorageData.values);
      localStorage.removeItem(salesforceStore.salesforceOpportunityId || salesforceStore.salesforceLeadId);
    } else {
      parsedFormData = JSON.parse(get(quoteData, 'savedFormData'));
    }

    setValues({
      ...values,
      ...parsedFormData,
      firstName: get(Policyholder, 'FirstName'),
      lastName: get(Policyholder, 'LastName'),
      email: get(Policyholder, 'Email'),
      phone: get(Policyholder, 'Telephone'),
      CompanyName: get(Policyholder, 'CompanyName'),
      EffectivePeriodStartDate: new Date(get(parsedFormData, 'EffectivePeriodStartDate'))
    });
  }

  try {
    if (localStorageData) {
      fillStepStoreValues(localStorageData);
    } else {
      fillStepStoreValues(quoteData);
    }
  } catch (e) {
    console.error(e);
  }

  salesforceStore.salesforceContactId = quoteData.contactId;
  salesforceStore.setCreated(true);
};
